import {
  ARBITRUM,
  ARBITRUM_TESTNET,
  AVALANCHE,
  MAINNET,
  TESTNET,
  SHARDEUM_TESTNET,
  ZKSYNC_TESTNET,
  ZETACHAIN_TESTNET,
  TEN_TESTNET,
  IOTA_TESTNET,
  FIRE_CHAIN_TESTNET,
  FIRE_CHAIN_MAINNET,
  SHIMMER_MAINNET,
  BERACHAIN_TESTNET,
  HYPERLIQUID_TESTNET,
} from "./chains";

import DEXY_ABI from "abis/DexynthToken_Testnet.json";
//import Factory_ABI from "";
import Fuckies_ABI from "abis/DexynthFuckies_Testnet.json";
//import LINK_ABI from "";
//import LPToken_ABI from "";
import FuckiesTrade_ABI from "abis/DexynthFuckiesTrade.json";
import LockedDepositNFT_ABI from "abis/GTokenLockedDepositNft.json";
//import NFT1ContractAddress_ABI from "";
//import NFT2ContractAddress_ABI from "";
//import NFT3ContractAddress_ABI from "";
//import NFT4ContractAddress_ABI from "";
//import NFT5ContractAddress_ABI from "";
//import NftRewards_ABI from "";
import OpenPnlFeed_ABI from "abis/DexynthTokenVaultOpenPnlFeed.json";
import PairInfos_ABI from "abis/DexynthPairInfosV6_1.json";
import PairsStorage_ABI from "abis/DexynthPairsStorageV6.json";
import PriceAggregator_ABI from "abis/DexynthPriceAggregatorV6_2_LB.json";
import Referrals_ABI from "abis/DexynthReferralsV6.json";
import Router_ABI from "abis/Router.json";
import Staking_ABI from "abis/DexynthStaking.json";
import Trading_ABI from "abis/DexynthTradingV6_4_1.json";
//import TradingCallbacks_ABI from "";
import TradingStorage_ABI from "abis/DexynthTradingStorageV5.json";
import USDC_ABI from "abis/USDT.json";
import dUSDC_ABI from "abis/DexynthTokenVault.json";
import borrowingFees_ABI from "abis/DexynthBorrowingFeesV6_4.json";

export const DEXY = "DEXY";
export const Factory = "Factory";
export const Fuckies = "Fuckies";
export const LINK = "LINK";
export const LPToken = "LPToken";
export const FuckiesTrade = "FuckiesTrade";
export const LockedDepositNFT = "LockedDepositNFT";
export const NFT1ContractAddress = "NFT1ContractAddress";
export const NFT2ContractAddress = "NFT2ContractAddress";
export const NFT3ContractAddress = "NFT3ContractAddress";
export const NFT4ContractAddress = "NFT4ContractAddress";
export const NFT5ContractAddress = "NFT5ContractAddress";
export const NftRewards = "NftRewards";
export const OpenPnlFeed = "OpenPnlFeed";
export const PairInfos = "PairInfos";
export const PairsStorage = "PairsStorage";
export const PriceAggregator = "PriceAggregator";
export const Referrals = "Referrals";
export const Router = "Router";
export const Staking = "Staking";
export const Trading = "Trading";
export const TradingCallbacks = "TradingCallbacks";
export const TradingStorage = "TradingStorage";
export const USDC = "USDC";
export const dUSDC = "dUSDC";
export const BorrowingFees = "borrowingFees";

export const ABI_INFO = {
  [USDC]: USDC_ABI,
  [DEXY]: DEXY_ABI,
  [Factory]: null,
  [Fuckies]: Fuckies_ABI,
  [LINK]: null,
  [LPToken]: null,
  [FuckiesTrade]: FuckiesTrade_ABI,
  [LockedDepositNFT]: LockedDepositNFT_ABI,
  [NFT1ContractAddress]: null,
  [NFT2ContractAddress]: null,
  [NFT3ContractAddress]: null,
  [NFT4ContractAddress]: null,
  [NFT5ContractAddress]: null,
  [NftRewards]: null,
  [OpenPnlFeed]: OpenPnlFeed_ABI,
  [PairInfos]: PairInfos_ABI,
  [PairsStorage]: PairsStorage_ABI,
  [PriceAggregator]: PriceAggregator_ABI,
  [Referrals]: Referrals_ABI,
  [Router]: Router_ABI,
  [Staking]: Staking_ABI,
  [Trading]: Trading_ABI,
  [TradingCallbacks]: null,
  [TradingStorage]: TradingStorage_ABI,
  [dUSDC]: dUSDC_ABI,
  [BorrowingFees]: borrowingFees_ABI,
};

export const XGMT_EXCLUDED_ACCOUNTS = [
  "0x330eef6b9b1ea6edd620c825c9919dc8b611d5d5",
  "0xd9b1c23411adbb984b1c4be515fafc47a12898b2",
  "0xa633158288520807f91ccc98aa58e0ea43acb400",
  "0xffd0a93b4362052a336a7b22494f1b77018dd34b",
];

const DexynthContracts_Testnet = "0xa4D12013643294B7eE8Ab561Cd85c57D3d1191aa";

const BUSD_ADDRESS_Testnet = "0x0C167A8f3fBfB3134558c64f0F9117Fb164ea32f";
const FACTORY_ADDRESS_Testnet = "0x82d74b01c7703FAd93A62666B7246Aa84f91724B";
const ROUTER_ADDRESS_Testnet = "0xFbF0Fec176Feb91ebf9B5E337D649866e418A6B4";
const OURTOKEN_ADDRESS_Testnet = "0xc3d8A160A5B2A4635C7a28bb74e53c6A0874fc4a";
const LPTOKEN_ADDRESS_Testnet = "0x0B0Ec54B5F556F6c1216fB1bFB8b0ccD76FAeC12";
const GFarmTradingStorageV5_Testnet = "0x5d34567bf5f9e1a70C6E1Aaf5503402dC0cd5dcF";
const GNSPairsStorageV6_Testnet = "0x1A624b27822fB0D17AEdAd2037bb4C2E3a45D801";
const GNSNftRewardsV6_Testnet = "0x8553149361dd7643493cee4003C7902dcd1Be1Bc";
const GNSPairInfosV6_1_Testnet = "0x1b8927262CCBE4d282497404f343890E5f1AC0E4";
const GNSReferralsV6_2_Testnet = "0x5D967104FD2fb6D2F2c268FdC400CDB3b4ebe04c";
const GNSTradingV6_2_Testnet = "0x2df7fFbf9FCE022a5bC1390E7E39636BC47C6195";
const GFarmNft1_Testnet = "0x0491f1A62fFB9cC9c561EB05EeCFA821F5b7dFbF";
const GFarmNft2_Testnet = "0xd04CC19BcA729413C970274FE356D049a1059f4d";
const GFarmNft3_Testnet = "0xD8B40b17d47cD82049B08B28ecE746cd1E8748d2";
const GFarmNft4_Testnet = "0xA68B15a5E6C788a30143BD92d65b76B7cE54cDE6";
const GFarmNft5_Testnet = "0xeAc36BfEC65f1E720483071F49fd0D665134907f";
const GNSStakingV6_2_Testnet = "0xa573043AA70e79b8Dc378C57ddB452d21f5F6D24";
const GNSPriceAggregatorV6_2_Testnet = "0x3460381CCA71a0d6A9678f2B022E224Dac6414C0";
const GNSTradingCallbacksV6_3_Testnet = "0xD6A2DaeDb26F57990459A17ddC658e18f5125C05";
const GTokenOpenPnlFeed_Testnet = "0x232B8BfE3a5BA3a232DdD50E49F57Df7ac7DC9Dc";
const dBUSD_Testnet = "0x0e287A67Ed2CDe3F830d9896805998823263917a";
const GTokenLockedDepositNFT_Testnet = "0xfD6e8085F03DBC75D60CC00983D4fDae8A704D8e";
const BEP20LINK_Testnet = "0x84b9B910527Ad5C03A9Ca831909E21e236EA7b06";
const Fuckies_Testnet = "0xFAa5e8F72B53d1aC3853Cb2032C6571cD8cEdc12";
const FuckiesTradeC_Testnet = "0xf3AAe835C981f83632E38DEA302A0d89c3045aF2";
const GNSPoolV5_Testnet = "0x09838d36f3ABA737B26112941aCC36B6eca71A68";

export const WS_TO_FRONT_NAME_MAP = {
  DEXYContractAddress: DEXY,
  factoryContractAddress: Factory,
  fuckiesContractAddress: Fuckies,
  fuckiesTradeContractAddress: FuckiesTrade,
  LINKContractAddress: LINK,
  LPTokenContractAddress: LPToken,
  lockedDepositNFTContractAddress: LockedDepositNFT,
  /*NFT1ContractAddress: NFT1ContractAddress,
  NFT2ContractAddress: NFT2ContractAddress,
  NFT3ContractAddress: NFT3ContractAddress,
  NFT4ContractAddress: NFT4ContractAddress,
  NFT5ContractAddress: NFT5ContractAddress,
  NFTRewardsContractAddress: NftRewards,*/
  openPnLFeedContractAddress: OpenPnlFeed,
  pairInfosContractAddress: PairInfos,
  pairsStorageContractAddress: PairsStorage,
  priceAggregatorContractAddress: PriceAggregator,
  referralsContractAddress: Referrals,
  routerContractAddress: Router,
  stakingContractAddress: Staking,
  tradingContractAddress: Trading,
  tradingCallbacksContractAddress: TradingCallbacks,
  tradingStorageContractAddress: TradingStorage,
  USDContractAddress: USDC,
  dUSDContractAddress: dUSDC,
  borrowingFeesContractAddress: BorrowingFees,
};

const CONTRACTS = {
  [MAINNET]: {
    // bsc mainnet
    Treasury: "0xa44E7252a0C137748F523F112644042E5987FfC7",
    BUSD: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
    GMT: "0x99e92123eB77Bc8f999316f622e5222498438784",
    Vault: "0xc73A8DcAc88498FD4b4B1b2AaA37b0a2614Ff67B",
    Router: "0xD46B23D042E976F8666F554E928e0Dc7478a8E1f",
    Reader: "0x087A618fD25c92B61254DBe37b09E5E8065FeaE7",
    AmmFactory: "0xBCfCcbde45cE874adCB698cC183deBcF17952812",
    AmmFactoryV2: "0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73",
    OrderBook: "0x1111111111111111111111111111111111111111",
    OrderBookReader: "0x1111111111111111111111111111111111111111",
    GmxMigrator: "0xDEF2af818514c1Ca1A9bBe2a4D45E28f260063f9",
    USDG: "0x85E76cbf4893c1fbcB34dCF1239A91CE2A4CF5a7",
    NATIVE_TOKEN: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    XGMT: "0xe304ff0983922787Fd84BC9170CD21bF78B16B10",
    GMT_USDG_PAIR: "0xa41e57459f09a126F358E118b693789d088eA8A0",
    XGMT_USDG_PAIR: "0x0b622208fc0691C2486A3AE6B7C875b4A174b317",
    GMT_USDG_FARM: "0x3E8B08876c791dC880ADC8f965A02e53Bb9C0422",
    XGMT_USDG_FARM: "0x68D7ee2A16AB7c0Ee1D670BECd144166d2Ae0759",
    USDG_YIELD_TRACKER: "0x0EF0Cf825B8e9F89A43FfD392664131cFB4cfA89",
    XGMT_YIELD_TRACKER: "0x82A012A9b3003b18B6bCd6052cbbef7Fa4892e80",
    GMT_USDG_FARM_TRACKER_XGMT: "0x08FAb024BEfcb6068847726b2eccEAd18b6c23Cd",
    GMT_USDG_FARM_TRACKER_NATIVE: "0xd8E26637B34B2487Cad1f91808878a391134C5c2",
    XGMT_USDG_FARM_TRACKER_XGMT: "0x026A02F7F26C1AFccb9Cba7C4df3Dc810F4e92e8",
    XGMT_USDG_FARM_TRACKER_NATIVE: "0x22458CEbD14a9679b2880147d08CA1ce5aa40E84",
    AUTO: "0xa184088a740c695E156F91f5cC086a06bb78b827",
    AUTO_USDG_PAIR: "0x0523FD5C53ea5419B4DAF656BC1b157dDFE3ce50",
    AUTO_USDG_FARM: "0xE6958298328D02051769282628a3b4178D0F3A47",
    AUTO_USDG_FARM_TRACKER_XGMT: "0x093b8be41c1A30704De84a9521632f9a139c08bd",
    AUTO_USDG_FARM_TRACKER_NATIVE: "0x23ed48E5dce3acC7704d0ce275B7b9a0e346b63A",
    GMT_GMX_IOU: "0x47052469970C2484729875CC9E2dd2683fcE71fb",
    XGMT_GMX_IOU: "0xeB3733DFe3b68C9d26898De2493A3Bb59FDb4A7B",
    GMT_USDG_GMX_IOU: "0x481312655F81b5e249780A6a49735335BF6Ca7f4",
    XGMT_USDG_GMX_IOU: "0x8095F1A92526C304623483018aA28cC6E62EB1e1",
    PositionRouter: "0x0000000000000000000000000000000000000000",
    PositionManager: "0x0000000000000000000000000000000000000000",
    GlpManager: "0x0000000000000000000000000000000000000000",
    GMX: "0x0000000000000000000000000000000000000000",
    GLP: "0x0000000000000000000000000000000000000000",
    VaultReader: "0x0000000000000000000000000000000000000000",
    GLP1: "0x0000000000000000000000000000000000000000",
    GLP2: "0x0000000000000000000000000000000000000000",
    GLP3: "0x0000000000000000000000000000000000000000",
    GLP4: "0x0000000000000000000000000000000000000000",
    GLP5: "0x0000000000000000000000000000000000000000",
    GLP6: "0x0000000000000000000000000000000000000000",
    GLP7: "0x0000000000000000000000000000000000000000",
    GLP8: "0x0000000000000000000000000000000000000000",
    GLP9: "0x0000000000000000000000000000000000000000",
    GLP0: "0x0000000000000000000000000000000000000000",
    ReferralStorage: "0x0000000000000000000000000000000000000000",
    ReferralReader: "0x0000000000000000000000000000000000000000",
  },
  [TESTNET]: {
    // bsc testnet
    Vault: "0x1B183979a5cd95FAF392c8002dbF0D5A1C687D9a",
    Router: "0x10800f683aa564534497a5b67F45bE3556a955AB",
    Reader: "0x98D4742F1B6a821bae672Cd8721283b91996E454",
    AmmFactory: "0x6725f303b657a9451d8ba641348b6761a6cc7a17",
    AmmFactoryV2: "0x1111111111111111111111111111111111111111",
    OrderBook: "0x9afD7B4f0b58d65F6b2978D3581383a06b2ac4e9",
    OrderBookReader: "0x0713562970D1A802Fa3FeB1D15F9809943982Ea9",
    GmxMigrator: "0xDEF2af818514c1Ca1A9bBe2a4D45E28f260063f9",
    USDG: "0x2D549bdBf810523fe9cd660cC35fE05f0FcAa028",
    GMT: "0xedba0360a44f885ed390fad01aa34d00d2532817",
    NATIVE_TOKEN: "0x612777Eea37a44F7a95E3B101C39e1E2695fa6C2",
    XGMT: "0x28cba798eca1a3128ffd1b734afb93870f22e613",
    GMT_USDG_PAIR: "0xe0b0a315746f51932de033ab27223d85114c6b85",
    XGMT_USDG_PAIR: "0x0108de1eea192ce8448080c3d90a1560cf643fa0",
    GMT_USDG_FARM: "0xbe3cB06CE03cA692b77902040479572Ba8D01b0B",
    XGMT_USDG_FARM: "0x138E92195D4B99CE3618092D3F9FA830d9A69B4b",
    USDG_YIELD_TRACKER: "0x62B49Bc3bF252a5DB26D88ccc7E61119e3179B4f",
    XGMT_YIELD_TRACKER: "0x5F235A582e0993eE9466FeEb8F7B4682993a57d0",
    GMT_USDG_FARM_TRACKER_XGMT: "0x4f8EE3aE1152422cbCaFACd4e3041ba2D859913C",
    GMT_USDG_FARM_TRACKER_NATIVE: "0xd691B26E544Fe370f39A776964c991363aF72e56",
    XGMT_USDG_FARM_TRACKER_XGMT: "0xfd5617CFB082Ba9bcD62d654603972AE312bC695",
    XGMT_USDG_FARM_TRACKER_NATIVE: "0x0354387DD85b7D8aaD1611B3D167A384d6AE0c28",
    GMT_GMX_IOU: "0x47052469970C2484729875CC9E2dd2683fcE71fb",
    XGMT_GMX_IOU: "0xeB3733DFe3b68C9d26898De2493A3Bb59FDb4A7B",
    GMT_USDG_GMX_IOU: "0x481312655F81b5e249780A6a49735335BF6Ca7f4",
    XGMT_USDG_GMX_IOU: "0x8095F1A92526C304623483018aA28cC6E62EB1e1",
    PositionRouter: "0x0000000000000000000000000000000000000000",
    PositionManager: "0x0000000000000000000000000000000000000000",
    GlpManager: "0x0000000000000000000000000000000000000000",
    GMX: "0x0000000000000000000000000000000000000000",
    GLP: "0x0000000000000000000000000000000000000000",
    VaultReader: "0x0000000000000000000000000000000000000000",
    GLP1: "0x0000000000000000000000000000000000000000",
    GLP2: "0x0000000000000000000000000000000000000000",
    GLP3: "0x0000000000000000000000000000000000000000",
    GLP4: "0x0000000000000000000000000000000000000000",
    GLP5: "0x0000000000000000000000000000000000000000",
    GLP6: "0x0000000000000000000000000000000000000000",
    GLP7: "0x0000000000000000000000000000000000000000",
    GLP8: "0x0000000000000000000000000000000000000000",
    GLP9: "0x0000000000000000000000000000000000000000",
    GLP0: "0x0000000000000000000000000000000000000000",
    ReferralStorage: "0x0000000000000000000000000000000000000000",
    ReferralReader: "0x0000000000000000000000000000000000000000",
    DexynthContracts: DexynthContracts_Testnet,
    GToken_Testnet: dBUSD_Testnet,
    dBUSD_Testnet: dBUSD_Testnet,
    DEXY_ADDRESS_Testnet: OURTOKEN_ADDRESS_Testnet,
    OURTOKEN_ADDRESS_Testnet: OURTOKEN_ADDRESS_Testnet,
    GNSTradingV6_2: GNSTradingV6_2_Testnet,
    GNSStakingV6_2: GNSStakingV6_2_Testnet,
    GNSPriceAggregatorV6_2: GNSPriceAggregatorV6_2_Testnet,
    GainsNetworkToken_Testnet: OURTOKEN_ADDRESS_Testnet,
    GFarmTradingStorageV5_Testnet: GFarmTradingStorageV5_Testnet,
    GNSTradingVaultV5_Testnet: dBUSD_Testnet,
    BUSD_ADDRESS_Testnet: BUSD_ADDRESS_Testnet,
    GTokenOpenPnlFeed_Testnet: GTokenOpenPnlFeed_Testnet,
    GNSPairsStorageV6: GNSPairsStorageV6_Testnet,
    GNSPairInfosV6_1: GNSPairInfosV6_1_Testnet,
    GNSReferralsV6_2: GNSReferralsV6_2_Testnet,
  },
  [SHARDEUM_TESTNET]: {
    DEXY: "0xc4c4efA357635D6c5A6e17bF9943A072319dD650",
    Factory: "0xFf1718845Aac53aA780a1b79abAE4886078F3ee1",
    Fuckies: "0x1A6809D620ED81772381E99Dbdb3DE335d494D9C",
    FuckiesTrade: "0x56bf704ed5a5d8CD582486C0500591d9593DB691",
    LINK: "0xCFdE50D1123126C7e3372c4Ed432bE3A27c972ed",
    LPToken: "0x369F78bdd9ae0a2bb424F78e2FdB017eB5B1c0a0",
    NFT1ContractAddress: "0x3Fa7C4BE9A2d061096c1d9A6090cb251bdD069e8",
    NFT2ContractAddress: "0xE294F4Dde101dDDFF941845492FA92b67FF52c10",
    NFT3ContractAddress: "0x6462119a1E8a0E2d02eb66c3a56EC8EDB1609FD3",
    NFT4ContractAddress: "0xC28aFfbc0324983b2dA423798f432f3AB8246092",
    NFT5ContractAddress: "0x9efEA9412b4042883D94ddEccfBcd33581c447D4",
    NftRewards: "0x6a2E29ca5D6bb48A5f6B3FCc0B66064bf62d3eeE",
    OpenPnlFeed: "0x01E59b56F36ed2C8a9bcc4d6955D807bB5B8d020",
    PairInfos: "0x2D952D6071D4158b96264756d564c156504BcA28",
    PairsStorage: "0x09932fB8a2e93E80127aF043eFbBcbEe38ACeee2",
    PriceAggregator: "0x2ffC32143B701F123376F5415f6423E1627F3735",
    Referrals: "0x382137c7Ba170aE5132bd17434F7d2d56308C04F",
    Router: "0x02Fe31EF06b51170762c4fa1a4ADb4a863235a48",
    Staking: "0x99f3dD9A892e21de871b6A1046eD5197C67a1cd6",
    Trading: "0x47B65466193C3329B4a641DfD43856cE55311550",
    TradingCallbacks: "0x8acc12A3f923BFe02B67bA7132A862a76B428f9D",
    TradingStorage: "0x8A28c68306fd0511162eFea0eC50d43d36F248D4",
    USDC: "0x739c0b8091d0bAC8b28CFC8D4Ce2ec8756Aeb7Bf",
    dUSDC: "0x76dfb2E7132cFDf7Da720d4FE3E14A23d8ce179A",
    // MIERDA
    GNSTradingVaultV5_Testnet: "0x0000000000000000000000000000000000000000",
    NATIVE_TOKEN: "0x0000000000000000000000000000000000000000",
    Vault: "0x0000000000000000000000000000000000000000",
    PositionRouter: "0x0000000000000000000000000000000000000000",
    Reader: "0x0000000000000000000000000000000000000000",
    USDG: "0x0000000000000000000000000000000000000000",
    LockedDepositNFT: "0x0000000000000000000000000000000000000000",
  },
  [ZKSYNC_TESTNET]: {
    GNSTradingVaultV5_Testnet: "0x0000000000000000000000000000000000000000",
    NATIVE_TOKEN: "0x0000000000000000000000000000000000000000",
    Vault: "0x0000000000000000000000000000000000000000",
    PositionRouter: "0x0000000000000000000000000000000000000000",
    Reader: "0x0000000000000000000000000000000000000000",
    USDG: "0x0000000000000000000000000000000000000000",
    LockedDepositNFT: "0x0000000000000000000000000000000000000000",
  },
  [ZETACHAIN_TESTNET]: {
    GNSTradingVaultV5_Testnet: "0x0000000000000000000000000000000000000000",
    NATIVE_TOKEN: "0x0000000000000000000000000000000000000000",
    Vault: "0x0000000000000000000000000000000000000000",
    PositionRouter: "0x0000000000000000000000000000000000000000",
    Reader: "0x0000000000000000000000000000000000000000",
    USDG: "0x0000000000000000000000000000000000000000",
    LockedDepositNFT: "0x0000000000000000000000000000000000000000",
  },
  [TEN_TESTNET]: {
    GNSTradingVaultV5_Testnet: "0x0000000000000000000000000000000000000000",
    NATIVE_TOKEN: "0x0000000000000000000000000000000000000000",
    Vault: "0x0000000000000000000000000000000000000000",
    PositionRouter: "0x0000000000000000000000000000000000000000",
    Reader: "0x0000000000000000000000000000000000000000",
    USDG: "0x0000000000000000000000000000000000000000",
    LockedDepositNFT: "0x0000000000000000000000000000000000000000",
  },
  [IOTA_TESTNET]: {
    GNSTradingVaultV5_Testnet: "0x0000000000000000000000000000000000000000",
    NATIVE_TOKEN: "0x0000000000000000000000000000000000000000",
    Vault: "0x0000000000000000000000000000000000000000",
    PositionRouter: "0x0000000000000000000000000000000000000000",
    Reader: "0x0000000000000000000000000000000000000000",
    USDG: "0x0000000000000000000000000000000000000000",
    LockedDepositNFT: "0x0000000000000000000000000000000000000000",
  },
  [FIRE_CHAIN_TESTNET]: {
    GNSTradingVaultV5_Testnet: "0x0000000000000000000000000000000000000000",
    NATIVE_TOKEN: "0x0000000000000000000000000000000000000000",
    Vault: "0x0000000000000000000000000000000000000000",
    PositionRouter: "0x0000000000000000000000000000000000000000",
    Reader: "0x0000000000000000000000000000000000000000",
    USDG: "0x0000000000000000000000000000000000000000",
    LockedDepositNFT: "0x0000000000000000000000000000000000000000",
  },
  [FIRE_CHAIN_MAINNET]: {
    GNSTradingVaultV5_Testnet: "0x0000000000000000000000000000000000000000",
    NATIVE_TOKEN: "0x0000000000000000000000000000000000000000",
    Vault: "0x0000000000000000000000000000000000000000",
    PositionRouter: "0x0000000000000000000000000000000000000000",
    Reader: "0x0000000000000000000000000000000000000000",
    USDG: "0x0000000000000000000000000000000000000000",
    LockedDepositNFT: "0x0000000000000000000000000000000000000000",
  },
  [SHIMMER_MAINNET]: {
    GNSTradingVaultV5_Testnet: "0x0000000000000000000000000000000000000000",
    NATIVE_TOKEN: "0x0000000000000000000000000000000000000000",
    Vault: "0x0000000000000000000000000000000000000000",
    PositionRouter: "0x0000000000000000000000000000000000000000",
    Reader: "0x0000000000000000000000000000000000000000",
    USDG: "0x0000000000000000000000000000000000000000",
    LockedDepositNFT: "0x0000000000000000000000000000000000000000",
  },
  [BERACHAIN_TESTNET]: {
    GNSTradingVaultV5_Testnet: "0x0000000000000000000000000000000000000000",
    NATIVE_TOKEN: "0x0000000000000000000000000000000000000000",
    Vault: "0x0000000000000000000000000000000000000000",
    PositionRouter: "0x0000000000000000000000000000000000000000",
    Reader: "0x0000000000000000000000000000000000000000",
    USDG: "0x0000000000000000000000000000000000000000",
    LockedDepositNFT: "0x0000000000000000000000000000000000000000",
  },
  [HYPERLIQUID_TESTNET]: {
    GNSTradingVaultV5_Testnet: "0x0000000000000000000000000000000000000000",
    NATIVE_TOKEN: "0x0000000000000000000000000000000000000000",
    Vault: "0x0000000000000000000000000000000000000000",
    PositionRouter: "0x0000000000000000000000000000000000000000",
    Reader: "0x0000000000000000000000000000000000000000",
    USDG: "0x0000000000000000000000000000000000000000",
    LockedDepositNFT: "0x0000000000000000000000000000000000000000",
  },
  [ARBITRUM_TESTNET]: {
    // arbitrum testnet
    Vault: "0xBc9BC47A7aB63db1E0030dC7B60DDcDe29CF4Ffb",
    Router: "0xe0d4662cdfa2d71477A7DF367d5541421FAC2547",
    VaultReader: "0xFc371E380262536c819D12B9569106bf032cC41c",
    Reader: "0x2E093c70E3A7E4919611d2555dFd8D697d2fC0a1",
    GlpManager: "0xD875d99E09118d2Be80579b9d23E83469077b498",
    RewardRouter: "0x0000000000000000000000000000000000000000",
    RewardReader: "0x0000000000000000000000000000000000000000",
    NATIVE_TOKEN: "0xB47e6A5f8b33b3F17603C83a0535A9dcD7E32681",
    GLP: "0xb4f81Fa74e06b5f762A104e47276BA9b2929cb27",
    GMX: "0x0000000000000000000000000000000000000000",
    ES_GMX: "0x0000000000000000000000000000000000000000",
    BN_GMX: "0x0000000000000000000000000000000000000000",
    USDG: "0xBCDCaF67193Bf5C57be08623278fCB69f4cA9e68",
    ES_GMX_IOU: "0x0000000000000000000000000000000000000000",
    StakedGmxTracker: "0x0000000000000000000000000000000000000000",
    BonusGmxTracker: "0x0000000000000000000000000000000000000000",
    FeeGmxTracker: "0x0000000000000000000000000000000000000000",
    StakedGlpTracker: "0x0000000000000000000000000000000000000000",
    FeeGlpTracker: "0x0000000000000000000000000000000000000000",

    StakedGmxDistributor: "0x0000000000000000000000000000000000000000",
    StakedGlpDistributor: "0x0000000000000000000000000000000000000000",

    GmxVester: "0x0000000000000000000000000000000000000000",
    GlpVester: "0x0000000000000000000000000000000000000000",

    OrderBook: "0xebD147E5136879520dDaDf1cA8FBa48050EFf016",
    OrderBookReader: "0xC492c8d82DC576Ad870707bb40EDb63E2026111E",

    PositionRouter: "0xB4bB78cd12B097603e2b55D2556c09C17a5815F8",
    PositionManager: "0x168aDa266c2f10C1F37973B213d6178551030e44",

    // UniswapGmxEthPool: "0x80A9ae39310abf666A87C743d6ebBD0E8C42158E",
    ReferralStorage: "0x0000000000000000000000000000000000000000",
    ReferralReader: "0x0000000000000000000000000000000000000000",
  },
  [ARBITRUM]: {
    // arbitrum mainnet
    Vault: "0x489ee077994B6658eAfA855C308275EAd8097C4A",
    Router: "0xaBBc5F99639c9B6bCb58544ddf04EFA6802F4064",
    VaultReader: "0xfebB9f4CAC4cD523598fE1C5771181440143F24A",
    Reader: "0x2b43c90D1B727cEe1Df34925bcd5Ace52Ec37694",
    GlpManager: "0x321F653eED006AD1C29D174e17d96351BDe22649",
    RewardRouter: "0xA906F338CB21815cBc4Bc87ace9e68c87eF8d8F1",
    RewardReader: "0x8BFb8e82Ee4569aee78D03235ff465Bd436D40E0",
    NATIVE_TOKEN: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
    GLP: "0x4277f8F2c384827B5273592FF7CeBd9f2C1ac258",
    GMX: "0xfc5A1A6EB076a2C7aD06eD22C90d7E710E35ad0a",
    ES_GMX: "0xf42Ae1D54fd613C9bb14810b0588FaAa09a426cA",
    BN_GMX: "0x35247165119B69A40edD5304969560D0ef486921",
    USDG: "0x45096e7aA921f27590f8F19e457794EB09678141",
    ES_GMX_IOU: "0x6260101218eC4cCfFF1b778936C6f2400f95A954",
    StakedGmxTracker: "0x908C4D94D34924765f1eDc22A1DD098397c59dD4",
    BonusGmxTracker: "0x4d268a7d4C16ceB5a606c173Bd974984343fea13",
    FeeGmxTracker: "0xd2D1162512F927a7e282Ef43a362659E4F2a728F",
    StakedGlpTracker: "0x1aDDD80E6039594eE970E5872D247bf0414C8903",
    FeeGlpTracker: "0x4e971a87900b931fF39d1Aad67697F49835400b6",

    StakedGmxDistributor: "0x23208B91A98c7C1CD9FE63085BFf68311494F193",
    StakedGlpDistributor: "0x60519b48ec4183a61ca2B8e37869E675FD203b34",

    GmxVester: "0x199070DDfd1CFb69173aa2F7e20906F26B363004",
    GlpVester: "0xA75287d2f8b217273E7FCD7E86eF07D33972042E",

    OrderBook: "0x09f77E8A13De9a35a7231028187e9fD5DB8a2ACB",
    OrderExecutor: "0x7257ac5D0a0aaC04AA7bA2AC0A6Eb742E332c3fB",
    OrderBookReader: "0xa27C20A7CF0e1C68C0460706bB674f98F362Bc21",

    PositionRouter: "0xb87a436B93fFE9D75c5cFA7bAcFff96430b09868",
    PositionManager: "0x75E42e6f01baf1D6022bEa862A28774a9f8a4A0C",

    UniswapGmxEthPool: "0x80A9ae39310abf666A87C743d6ebBD0E8C42158E",
    ReferralStorage: "0xe6fab3f0c7199b0d34d7fbe83394fc0e0d06e99d",
    ReferralReader: "0x8Aa382760BCdCe8644C33e6C2D52f6304A76F5c8",
  },
  [AVALANCHE]: {
    // avalanche
    Vault: "0x9ab2De34A33fB459b538c43f251eB825645e8595",
    Router: "0x5F719c2F1095F7B9fc68a68e35B51194f4b6abe8",
    VaultReader: "0x66eC8fc33A26feAEAe156afA3Cb46923651F6f0D",
    Reader: "0x2eFEE1950ededC65De687b40Fd30a7B5f4544aBd",
    GlpManager: "0xe1ae4d4b06A5Fe1fc288f6B4CD72f9F8323B107F",
    RewardRouter: "0x82147C5A7E850eA4E28155DF107F2590fD4ba327",
    RewardReader: "0x04Fc11Bd28763872d143637a7c768bD96E44c1b6",
    NATIVE_TOKEN: "0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7",
    GLP: "0x01234181085565ed162a948b6a5e88758CD7c7b8",
    GMX: "0x62edc0692BD897D2295872a9FFCac5425011c661",
    ES_GMX: "0xFf1489227BbAAC61a9209A08929E4c2a526DdD17",
    BN_GMX: "0x8087a341D32D445d9aC8aCc9c14F5781E04A26d2",
    USDG: "0xc0253c3cC6aa5Ab407b5795a04c28fB063273894",
    ES_GMX_IOU: "0x6260101218eC4cCfFF1b778936C6f2400f95A954", // placeholder address

    StakedGmxTracker: "0x2bD10f8E93B3669b6d42E74eEedC65dd1B0a1342",
    BonusGmxTracker: "0x908C4D94D34924765f1eDc22A1DD098397c59dD4",
    FeeGmxTracker: "0x4d268a7d4C16ceB5a606c173Bd974984343fea13",
    StakedGlpTracker: "0x9e295B5B976a184B14aD8cd72413aD846C299660",
    FeeGlpTracker: "0xd2D1162512F927a7e282Ef43a362659E4F2a728F",

    StakedGmxDistributor: "0xfc5A1A6EB076a2C7aD06eD22C90d7E710E35ad0a",
    StakedGlpDistributor: "0xDd593Cf40734199afc9207eBe9ffF23dA4Bf7720",

    GmxVester: "0x472361d3cA5F49c8E633FB50385BfaD1e018b445",
    GlpVester: "0x62331A7Bd1dfB3A7642B7db50B5509E57CA3154A",

    OrderBook: "0x4296e307f108B2f583FF2F7B7270ee7831574Ae5",
    OrderExecutor: "0x4296e307f108B2f583FF2F7B7270ee7831574Ae5",
    OrderBookReader: "0xccFE3E576f8145403d3ce8f3c2f6519Dae40683B",

    PositionRouter: "0xffF6D276Bc37c61A23f06410Dce4A400f66420f8",
    PositionManager: "0xA21B83E579f4315951bA658654c371520BDcB866",

    TraderJoeGmxAvaxPool: "0x0c91a070f862666bbcce281346be45766d874d98",
    ReferralStorage: "0x827ed045002ecdabeb6e2b0d1604cf5fc3d322f8",
    ReferralReader: "0x505Ce16D3017be7D76a7C2631C0590E71A975083",
  },
};

export function getContract(chainId: number, name: string): string {
  if (CONTRACTS[chainId] && CONTRACTS[chainId][name]) {
    return CONTRACTS[chainId][name];
  } else {
    return "0x0000000000000000000000000000000000000000";
  }
}

export function setContract(chainId: number, name: string, address: string) {
  if (CONTRACTS[chainId]) {
    CONTRACTS[chainId][name] = address;
  }
}

export const HARDCODED_TESTNET_ADDRESSES = ` {
  "97": {
    "DEXYContractAddress": "0xBf611d6b4FE55440E7E19a6Eb8aFe2c5341DAbe3",
    "factoryContractAddress": "0x2c7803341aA3012D2129D3Ea41520ECc0c65343d",
    "fuckiesContractAddress": "0x9c3B0924BDB58629eaDe32f66e95E35cC39888AA",
    "fuckiesTradeContractAddress": "0xF6d69DE106A90962d5F3aCe529440D0D2A549112",
    "LINKContractAddress": "0x84b9B910527Ad5C03A9Ca831909E21e236EA7b06",
    "LPTokenContractAddress": "0x017053C84a9a3eff8C0810B2d297Fe05324EC66b",
    "lockedDepositNFTContractAddress": "0x814C692Aed6EA08B801F215C88bB05F276c72003",
    "NFT1ContractAddress": "0x3Fa7C4BE9A2d061096c1d9A6090cb251bdD069e8",
    "NFT2ContractAddress": "0xE294F4Dde101dDDFF941845492FA92b67FF52c10",
    "NFT3ContractAddress": "0x6462119a1E8a0E2d02eb66c3a56EC8EDB1609FD3",
    "NFT4ContractAddress": "0xC28aFfbc0324983b2dA423798f432f3AB8246092",
    "NFT5ContractAddress": "0x9efEA9412b4042883D94ddEccfBcd33581c447D4",
    "NFTRewardsContractAddress": "0x1fc38851D17426936a760a8eE9deD195f9620c96",
    "openPnLFeedContractAddress": "0xDBb846316bBA49719F23021530B33b2f7df304e5",
    "pairInfosContractAddress": "0x2bf587d20201dBF91194937987Ba074d2a31C5F9",
    "pairsStorageContractAddress": "0xB8cE620AB2B83D4d6990224Ed2748f9776686C73",
    "priceAggregatorContractAddress": "0x862fC63b6d0fc527a3123cEDF3C1350A5ff7C5e3",
    "referralsContractAddress": "0x36918b4e32109F3D355D11Bd8b6a07FCd58B49Df",
    "routerContractAddress": "0xe64CB8dB8c72dEd6030D215157f9486444Eb97FF",
    "stakingContractAddress": "0x02577fC8cB30F29E614F3509063D9F706670daa6",
    "tradingContractAddress": "0x60bEA77Af574929BE586027C6D982846604c1f4e",
    "tradingCallbacksContractAddress": "0x6Dddf50Ca75f3699fce1316b361D2C9562CBc732",
    "tradingStorageContractAddress": "0x4a772459031771Ce1e9AdEE65A22482201dc5660",
    "USDContractAddress": "0x396B50DE00ef045eEb296e0C0fEAB93557Dc7D65",
    "dUSDContractAddress": "0x6428b460463390512A7D1CD11F04e7A4d9266458"
  },
  "8082": {
    "DEXYContractAddress": "0xBf611d6b4FE55440E7E19a6Eb8aFe2c5341DAbe3",
    "factoryContractAddress": "",
    "fuckiesContractAddress": "",
    "fuckiesTradeContractAddress": "",
    "LINKContractAddress": "",
    "LPTokenContractAddress": "",
    "lockedDepositNFTContractAddress": "",
    "NFT1ContractAddress": "",
    "NFT2ContractAddress": "",
    "NFT3ContractAddress": "",
    "NFT4ContractAddress": "",
    "NFT5ContractAddress": "",
    "NFTRewardsContractAddress": "",
    "openPnLFeedContractAddress": "",
    "pairInfosContractAddress": "",
    "pairsStorageContractAddress": "",
    "priceAggregatorContractAddress": "",
    "referralsContractAddress": "",
    "routerContractAddress": "",
    "stakingContractAddress": "",
    "tradingContractAddress": "",
    "tradingCallbacksContractAddress": "",
    "tradingStorageContractAddress": "",
    "USDContractAddress": "",
    "dUSDContractAddress": ""
  },
  "300": {
    "DEXYContractAddress": "0xD2A22469B6De7A69caaFA4881CfB227308EbE285",
    "factoryContractAddress": "0x9E9D690d13B139CE6ED479F151eCADc9559f43a0",
    "fuckiesContractAddress": "0x3654D816AebFC34A626a1C8919175578698325F6",
    "fuckiesTradeContractAddress": "0x3aAF1d7326ed9AfFd10d578fCd3c4bAe5AcD790E",
    "LINKContractAddress": "0x0000000000000000000000000000000000000000",
    "LPTokenContractAddress": "0x5431e11c487e0d1c7B77B7fc97aCe7244A79C19D",
    "lockedDepositNFTContractAddress": "0x8Cf947a883CD5Aa12d1b0971a943e71A6d422594",
    "NFT1ContractAddress": "0xdc41Fa5750F68eCB0DF45aA6E0e7e3976bB165a7",
    "NFT2ContractAddress": "0xc4d4EFAb356215dA2313239B5EE2aEF8a01cB50E",
    "NFT3ContractAddress": "0xAef7B8251F5c689F9d2387Bf1F746b2C9e0A6a39",
    "NFT4ContractAddress": "0xfEC77E576f92d9DC014F6D4c897b18F78f10f2f9",
    "NFT5ContractAddress": "0x80112D8A202fCC73aD67eF4F13643b9a9D4fF0e6",
    "NFTRewardsContractAddress": "0x5b6FB4deA26beeCC2d0c7D22E3D6A39eA02CE266",
    "openPnLFeedContractAddress": "0x21E179A7d292B7Fb0E97BA374AdCC53989720bBA",
    "pairInfosContractAddress": "0xc1Be5C957dC4E0026Cd1a3001a488d37a6a22BF7",
    "pairsStorageContractAddress": "0x4366493650c9bEc1f6E821b246AA184959B938E2",
    "priceAggregatorContractAddress": "0x615A219d45647875f7346eaEb7917b6484a93632",
    "referralsContractAddress": "0x3D0C022E8c9c51F571424e9b18C2cFb531FD7c7e",
    "routerContractAddress": "0xeC926BA6121EB1556e688cd9bd1902b180116195",
    "stakingContractAddress": "0xBE155527096d67844E6e7D98aF5Bd5098be2f6E5",
    "tradingContractAddress": "0x062444a373279196675E96B7aeC8De5570b2F856",
    "tradingCallbacksContractAddress": "0x88982F06941bf624d37A54028f217C9a03E5184c",
    "tradingStorageContractAddress": "0x1959474fDC5f36f1a63531e5D2f534e2d38777BC",
    "USDContractAddress": "0x9a2AA70D18ce24DABe29DbC320d4C56bdB406178",
    "dUSDContractAddress": "0x19676c7D1C94C949AE824c4D19C046D7DfcBf8E1"
  }
}`;
