import React, { useRef, useState } from "react";
import { Menu } from "@headlessui/react";
import ModalWithPortal from "../Modal/ModalWithPortal";
import { t, Trans } from "@lingui/macro";
import cx from "classnames";
import { HiDotsVertical } from "react-icons/hi";
import "./NetworkDropdown.css";
import language24Icon from "img/ic_language24.svg";
import settingsIcon from "img/ic_settings_16.svg";
import bnbIcon from "img/ic_bsc.svg";
import checkedIcon from "img/ic_checked.svg";
import { importImage } from "lib/legacy";
import { defaultLocale, dynamicActivate, isTestLanguage, locales } from "lib/i18n";
import { LANGUAGE_LOCALSTORAGE_KEY } from "config/localStorage";

import { NETWORK_OPTIONS, getIconSrc } from "config/chains";

import { HYPERLIQUID_TESTNET, TEN_TESTNET, FIRE_CHAIN_TESTNET, IOTA_TESTNET, SHARDEUM_TESTNET } from "config/chains";
import { IS_PROD } from "config/context";

const LANGUAGE_MODAL_KEY = "LANGUAGE";
const NETWORK_MODAL_KEY = "NETWORK";

export default function NetworkDropdown(props) {
  const { small, networkOptions, selectorLabel, openSettings, chainId } = props;

  const currentLanguage = useRef(localStorage.getItem(LANGUAGE_LOCALSTORAGE_KEY) || defaultLocale);
  const [activeModal, setActiveModal] = useState(null);

  function getModalContent(modalName) {
    switch (modalName) {
      case LANGUAGE_MODAL_KEY:
        return <LanguageModalContent currentLanguage={currentLanguage} />;
      case NETWORK_MODAL_KEY:
        return (
          <NetworkModalContent
            setActiveModal={setActiveModal}
            networkOptions={props.networkOptions}
            onNetworkSelect={props.onNetworkSelect}
            selectorLabel={props.selectorLabel}
            openSettings={props.openSettings}
          />
        );
      default:
        return;
    }
  }

  function getModalProps(modalName) {
    switch (modalName) {
      case LANGUAGE_MODAL_KEY:
        return {
          className: "language-popup",
          isVisible: activeModal === LANGUAGE_MODAL_KEY,
          setIsVisible: () => setActiveModal(null),
          label: t`Select Language`,
        };
      case NETWORK_MODAL_KEY:
        return {
          className: "network-popup",
          isVisible: activeModal === NETWORK_MODAL_KEY,
          setIsVisible: () => setActiveModal(null),
          label: t`Networks and Settings`,
        };
      default:
        return {};
    }
  }

  return (
    <>
      {props.small ? (
        <div className="App-header-network" onClick={() => setActiveModal(NETWORK_MODAL_KEY)}>
          <div className="network-dropdown">
            <NavIcons selectorLabel={props.selectorLabel} chainId={chainId} />
          </div>
        </div>
      ) : (
        <DesktopDropdown
          currentLanguage={currentLanguage}
          activeModal={activeModal}
          setActiveModal={setActiveModal}
          chainId={chainId}
          {...props}
        />
      )}
      <ModalWithPortal {...getModalProps(activeModal)}>{getModalContent(activeModal)}</ModalWithPortal>
    </>
  );
}
function NavIcons({ selectorLabel, chainId }) {
  return (
    <>
      <button className={cx("btn-primary small transparent")}>
        <img className="network-dropdown-icon" src={getIconSrc(chainId)} alt={selectorLabel} />
      </button>
      <div className="network-dropdown-seperator d-none-small" />
      <button className={cx("btn-primary small transparent d-none-small")}>
        <HiDotsVertical color="white" size={20} />
      </button>
    </>
  );
}

function DesktopDropdown({ setActiveModal, selectorLabel, networkOptions, onNetworkSelect, openSettings, chainId }) {
  //Vaciamos el array de las opciones y le agregamos la de la bsc testnet
  networkOptions = NETWORK_OPTIONS;
  return (
    <div className="App-header-network" style={{ height: "42px" }}>
      <Menu>
        <Menu.Button as="div" className="network-dropdown">
          <NavIcons selectorLabel={selectorLabel} chainId={chainId} />
        </Menu.Button>
        <Menu.Items as="div" style={{ right: "0px" }} className="menu-items network-dropdown-items">
          {/* <div className="dropdown-label">
            <Trans>Networks</Trans>
          </div> */}
          <div className="network-dropdown-list">
            <NetworkMenuItems
              networkOptions={networkOptions}
              selectorLabel={selectorLabel}
              onNetworkSelect={onNetworkSelect}
            />
          </div>
          {/* {!IS_PROD && (
            <>
              <div className="network-dropdown-divider" />
              <Menu.Item>
                <div className="network-dropdown-menu-item menu-item" onClick={openSettings}>
                  <div className="menu-item-group">
                    <div className="menu-item-icon">
                      <img className="network-dropdown-icon" src={settingsIcon} alt="" />
                    </div>
                    <span className="network-dropdown-item-label">
                      <Trans>Settings</Trans>
                    </span>
                  </div>
                </div>
              </Menu.Item>
              <Menu.Item>
                <div
                  className="network-dropdown-menu-item menu-item last-dropdown-menu"
                  onClick={() => setActiveModal(LANGUAGE_MODAL_KEY)}
                >
                  <div className="menu-item-group">
                    <div className="menu-item-icon">
                      <img className="network-dropdown-icon" src={language24Icon} alt="" />
                    </div>
                    <span className="network-dropdown-item-label">
                      <Trans>Language</Trans>
                    </span>
                  </div>
                </div>
              </Menu.Item>
            </>
          )} */}
        </Menu.Items>
      </Menu>
    </div>
  );
}

const mainnetNetworks = NETWORK_OPTIONS.filter((network) => network.mainnet && network.enabled);
let testnetNetworks = NETWORK_OPTIONS.filter((network) => !network.mainnet && network.enabled);

const chainOrder = [HYPERLIQUID_TESTNET, TEN_TESTNET, FIRE_CHAIN_TESTNET, IOTA_TESTNET, SHARDEUM_TESTNET];
testnetNetworks.sort((a, b) => chainOrder.indexOf(a.value) - chainOrder.indexOf(b.value));

//testnetNetworks.sort((a, b) => networkOrder.indexOf(a.label) - networkOrder.indexOf(b.label));

function NetworkMenuItems({ networkOptions, selectorLabel, onNetworkSelect }) {
  async function handleNetworkSelect(option) {
    await onNetworkSelect(option);
  }

  return (
    <div>
      {mainnetNetworks.length != 0 && <p>Mainnet</p>}
      {mainnetNetworks.map((network) => {
        const networkIcon = importImage(network.icon);
        const comingSoon = network.future ? " (Coming Soon)" : "";
        return (
          <Menu.Item key={network.value}>
            <div
              className="network-dropdown-menu-item menu-item"
              onClick={() => handleNetworkSelect({ value: network.value })}
            >
              <div className="menu-item-group">
                <div className="menu-item-icon">
                  <img
                    className="network-dropdown-icon network-dropdown-icon-img"
                    src={networkIcon}
                    alt={network.label}
                  />
                </div>
                <div>
                  <span style={{ color: selectorLabel == network.label ? "white" : "#3C3D43" }}>
                    {network.label + comingSoon}
                  </span>
                </div>
              </div>
              <div className="network-dropdown-menu-item-img">
                <div className={cx("active-dot", { [selectorLabel]: selectorLabel === network.label })} />
              </div>
            </div>
          </Menu.Item>
        );
      })}
      {testnetNetworks.length != 0 && <p style={{ marginTop: "5px" }}>Testnet</p>}
      {testnetNetworks.map((network) => {
        const networkIcon = importImage(network.icon);
        const comingSoon = network.future ? " (Coming Soon)" : "";
        return (
          <Menu.Item key={network.value}>
            <div
              className="network-dropdown-menu-item menu-item"
              onClick={() => (network.future ? null : handleNetworkSelect({ value: network.value }))}
            >
              <div className="menu-item-group">
                <div className="menu-item-icon">
                  <img
                    className="network-dropdown-icon network-dropdown-icon-img"
                    src={networkIcon}
                    alt={network.label + comingSoon}
                  />
                </div>
                <div>
                  <span
                    style={{
                      color: selectorLabel == network.label ? "white" : "#3C3D43",
                      opacity: network.future ? 0.8 : 1,
                    }}
                  >
                    {network.label + comingSoon}
                  </span>
                </div>
              </div>
              <div className="network-dropdown-menu-item-img">
                <div className={cx("active-dot", { [selectorLabel]: selectorLabel === network.label })} />
              </div>
            </div>
          </Menu.Item>
        );
      })}
    </div>
  );
}

function LanguageModalContent({ currentLanguage }) {
  return Object.keys(locales).map((item) => {
    const image = !isTestLanguage(item) && importImage(`flag_${item}.svg`);
    return (
      <div
        key={item}
        className={cx("network-dropdown-menu-item  menu-item language-modal-item", {
          active: currentLanguage.current === item,
        })}
        onClick={() => {
          if (!isTestLanguage(item)) {
            localStorage.setItem(LANGUAGE_LOCALSTORAGE_KEY, item);
          }
          dynamicActivate(item);
        }}
      >
        <div className="menu-item-group">
          <div className="menu-item-icon">
            {isTestLanguage(item) ? "🫐" : <img className="network-dropdown-icon" src={image} alt={locales[item]} />}
          </div>
          <span className="network-dropdown-item-label menu-item-label">{locales[item]}</span>
        </div>
        <div className="network-dropdown-menu-item-img">
          {currentLanguage.current === item && <img src={checkedIcon} alt={locales[item]} />}
        </div>
      </div>
    );
  });
}
function NetworkModalContent({ networkOptions, onNetworkSelect, selectorLabel, setActiveModal, openSettings }) {
  async function handleNetworkSelect(option) {
    await onNetworkSelect(option);
  }

  networkOptions.sort((a, b) => chainOrder.indexOf(a.value) - chainOrder.indexOf(b.value));

  return (
    <div className="network-dropdown-items">
      <div className="network-dropdown-list">
        <span className="network-dropdown-label">
          <Trans>Networks</Trans>
        </span>

        {networkOptions.map((network) => {
          if (!network.enabled) return null;
          const networkIcon = importImage(network.icon);
          const comingSoon = network.future ? " (Coming Soon)" : "";
          const disabledStyle = network.future ? { opacity: 0.2 } : {};
          return (
            <div
              className="network-option"
              style={disabledStyle}
              onClick={() => (network.future ? null : handleNetworkSelect({ value: network.value }))}
              key={network.value}
            >
              <div className="menu-item-group">
                <img src={networkIcon} alt={network.label} />
                <span>{network.label + comingSoon}</span>
              </div>
              <div className={cx("active-dot", { [selectorLabel]: selectorLabel === network.label })} />
            </div>
          );
        })}
        {!IS_PROD && (
          <>
            <span className="network-dropdown-label more-options">
              <Trans>More Options</Trans>
            </span>
            <div
              className="network-option"
              onClick={() => {
                setActiveModal(LANGUAGE_MODAL_KEY);
              }}
            >
              <div className="menu-item-group">
                <img className="network-option-img" src={language24Icon} alt="Select Language" />
                <span className="network-option-img-label">Language</span>
              </div>
            </div>
            <div
              className="network-option"
              onClick={() => {
                openSettings();
                setActiveModal(null);
              }}
            >
              <div className="menu-item-group">
                <img className="network-option-img" src={settingsIcon} alt="" />
                <span className="network-option-img-label">
                  <Trans>Settings</Trans>
                </span>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
