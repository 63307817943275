import { useState, useEffect, useRef } from "react";
import "./DashboardV3.css";
import { useStats } from "hooks/useStats";

import DexynthLogo from "../../img/new-dexynth-logo.png";

// Partners logo
import ShardeumPartner from "img/ShardeumPartner.svg";
import DAMEXPartner from "img/DAMEXPartner.svg";
import TV from "img/partners-svg/TradingView.svg";
import verichains from "img/VERICHAINS.svg";
import yellowPartner from "img/YellowPartner.svg";
import PrasagaPartner from "img/Prasaga 2 1.svg";
import OBSCPartner from "img/OBSC.svg";
import numenPartner from "img/numen-partner.svg";
import { Link } from "react-router-dom";
import Footer from "components/Footer/Footer";

import {
  ZKSYNC_TESTNET,
  OPBNB_TESTNET,
  SHARDEUM_TESTNET,
  ZETACHAIN_TESTNET,
  BERACHAIN_TESTNET,
  IOTA_TESTNET,
  TEN_TESTNET,
  FIRE_CHAIN_TESTNET,
} from "config/chains";

const words = ["Crypto", "Stocks", "Forex", "Commodities"];

export const DashboardV3 = () => {
  const [currentWordIndex, setCurrentWordIndex] = useState(0);

  const { stats: data } = useStats({ periodic: true, refreshInterval: 10000 });

  const [statsData, setStatsData] = useState({
    totalUsers: "",
    totalTrades: "",
    totalVolume: "",
  });

  const introRef = useRef(null);
  const infoRef = useRef(null);
  const defiRef = useRef(null);
  const partnersRef = useRef(null);
  const [activeSection, setActiveSection] = useState(null);

  const scrollToElement = (ref, sectionName) => {
    window.scrollTo({
      top: ref.offsetTop,
      left: 0,
      behavior: "smooth",
    });

    setActiveSection(sectionName);
  };

  useEffect(() => {
    if (!data) return;

    const zkSyncData = data[ZKSYNC_TESTNET];
    const opBnbData = data[OPBNB_TESTNET];
    const shmData = data[SHARDEUM_TESTNET];
    const zetaChain = data[ZETACHAIN_TESTNET];
    const berachainData = data[BERACHAIN_TESTNET];
    const tenData = data[TEN_TESTNET];
    const shimmerData = data[IOTA_TESTNET];
    const fireData = data[FIRE_CHAIN_TESTNET];

    console.log(berachainData);

    const totalUsers = Intl.NumberFormat("es-CL").format(
      zkSyncData?.uniqueUsers["all"] +
        shmData?.uniqueUsers["all"] +
        opBnbData?.uniqueUsers["all"] +
        zetaChain?.uniqueUsers["all"] +
        berachainData?.uniqueUsers["all"] +
        shimmerData?.uniqueUsers["all"] +
        tenData?.uniqueUsers["all"] +
        fireData?.uniqueUsers["all"]
    );

    const totalTrades = Intl.NumberFormat("es-CL").format(
      zkSyncData?.trades["all"] +
        shmData?.trades["all"] +
        opBnbData?.trades["all"] +
        zetaChain?.trades["all"] +
        berachainData?.trades["all"] +
        shimmerData?.trades["all"] +
        tenData?.trades["all"] +
        fireData?.trades["all"]
    );

    const totalVolume = Intl.NumberFormat("es-CL").format(
      (
        (zkSyncData?.volume["all"] +
          shmData?.volume["all"] +
          opBnbData?.volume["all"] +
          zetaChain?.volume["all"] +
          berachainData?.volume["all"] +
          shimmerData?.volume["all"] +
          tenData?.volume["all"] +
          fireData?.volume["all"]) /
        1e10
      ).toFixed(0)
    );

    setStatsData({
      totalUsers,
      totalTrades,
      totalVolume,
    });
  }, [data]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
      const spanElement = document.querySelector(".fade-in-up");

      if (spanElement) {
        spanElement.classList.add("fade-in");

        setTimeout(() => {
          spanElement.classList.remove("fade-in");
        }, 1000);
      }
    }, 2300);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      const elementos = Array.from(document.getElementsByClassName("text-info-container"));

      elementos.forEach((element) => {
        const spanElements = Array.from(element.getElementsByTagName("span"));

        spanElements.forEach((span) => {
          span.classList.add("textEffect");

          setTimeout(() => {
            span.classList.remove("textEffect");
          }, 5000);
        });
      });
    }, 30000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY + window.innerHeight / 2;
      const introPos = introRef.current?.offsetTop ?? 0;
      const defiPos = defiRef.current?.offsetTop ?? 0;
      const infoPos = infoRef.current?.offsetTop ?? 0;
      const partnersPos = partnersRef.current?.offsetTop ?? 0;

      if (scrollPosition >= introPos && scrollPosition < defiPos) {
        setActiveSection("intro");
      } else if (scrollPosition >= defiPos && scrollPosition < infoPos) {
        setActiveSection("defi");
      } else if (scrollPosition >= infoPos && scrollPosition < partnersPos) {
        setActiveSection("info");
      } else if (scrollPosition >= partnersPos) {
        setActiveSection("partners");
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    const elementos = Array.from(document.getElementsByClassName("text-info-container"));

    elementos.forEach((element) => {
      const spanElements = Array.from(element.getElementsByTagName("span"));

      spanElements.forEach((span) => {
        span.classList.add("textEffect");

        setTimeout(() => {
          span.classList.remove("textEffect");
        }, 5000);
      });
    });
  }, [statsData]);

  return (
    <div id="dashboard-V3-container">
      {/* <video autoPlay={true} muted loop={true} class="video-background">
        <source src={VideoBackground} type="video/webm" />
      </video> */}
      <div className="dashboard-select-page">
        <div>
          <button onClick={() => scrollToElement(introRef.current, "intro")}>
            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8">
              <circle cx="4" cy="4" r="3.5" fill={activeSection === "intro" ? "#DEDEDE" : "none"} stroke="#DEDEDE" />
            </svg>
          </button>
        </div>
        <div>
          <button onClick={() => scrollToElement(defiRef.current, "defi")}>
            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8">
              <circle cx="4" cy="4" r="3.5" fill={activeSection === "defi" ? "#DEDEDE" : "none"} stroke="#DEDEDE" />
            </svg>
          </button>
        </div>
        <div>
          <button onClick={() => scrollToElement(infoRef.current, "info")}>
            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8">
              <circle cx="4" cy="4" r="3.5" fill={activeSection === "info" ? "#DEDEDE" : "none"} stroke="#DEDEDE" />
            </svg>
          </button>
        </div>
        <div>
          <button onClick={() => scrollToElement(partnersRef.current, "partners")}>
            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8">
              <circle cx="4" cy="4" r="3.5" fill={activeSection === "partners" ? "#DEDEDE" : "none"} stroke="#DEDEDE" />
            </svg>
          </button>
        </div>
      </div>
      <div className="dashboard-header" ref={introRef}>
        <div className="dexynth-info">
          <img src={DexynthLogo} />
          <h1>SYNTHETICS TRADING</h1>
        </div>

        <div className="dexynth-volume">
          <div>
            <p>Total Trading Volume</p>
            <div className="text-info-container">
              {statsData.totalVolume.split("").map((letter, index) => {
                return (
                  <span className="animation-text" style={{ "--i": index }} key={index}>
                    {letter}
                  </span>
                );
              })}
            </div>
          </div>
          <div>
            <p>Total Users</p>
            <div className="text-info-container">
              {statsData.totalUsers.split("").map((letter, index) => {
                return (
                  <span className="animation-text" style={{ "--i": index }} key={index}>
                    {letter}
                  </span>
                );
              })}
            </div>
          </div>
          <div>
            <p>Total Trades</p>
            <div className="text-info-container">
              {statsData.totalTrades.split("").map((letter, index) => {
                return (
                  <span className="animation-text" style={{ "--i": index }} key={index}>
                    {letter}
                  </span>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      <div className="dexynth-trading" ref={defiRef}>
        <div className="trading-info">
          <h2>
            DeFi Synthetics Trading Trade <span className="orange fade-in-up">{words[currentWordIndex]}</span>
          </h2>

          <h3>From your Web3 Wallet. 1000X Leverage. No KYC</h3>
          <p>
            Enjoy effortless trading using USDT, eliminating the need to bridge between blockchains. Join now and trade
            without limitations!
          </p>

          <Link to="/trading" className="btn-trade">
            Trade Now
          </Link>
        </div>
      </div>

      <div className="dexynth-desc" ref={infoRef}>
        <div className="dexynth-logo">
          <div className="second">
            <div className="third">
              <div className="fourth">
                <div className="dexynth-features"></div>
                <div className="dexynth-logo-green"></div>
              </div>
            </div>
          </div>
        </div>

        <div className="desc-info">
          <div className="info">
            <h3>/ Multichain</h3>
            <div className="info-partners">
              <div className="shm"></div>
              <div className="hl"></div>
              <div className="ten">
                <span style={{ position: "relative", top: "3px", left: "40px" }}>TEN</span>
              </div>
            </div>
          </div>
          <div className="info">
            <h3>/ Median Spot Prices</h3>
            <p>
              Proprietary Oracle Network. <br /> Takes prices from different sources in order to give instant price
              feed.
            </p>
          </div>
          <div className="info">
            <h3>/ Aggregated Liquidity</h3>
            <p>
              Leverage the liquidity from multiple blockchains <br />
              as if it was altogether in one chain only.
            </p>
          </div>
          <div className="info">
            <h3>/ Direct from your Web3 Wallet</h3>
            <p>
              Enjoy effortless trading using only USDT, eliminating the need <br />
              to bridge between blockchains.
            </p>
          </div>

          <a href="https://docsend.com/view/m9gm9peratkya8bn" target="_blank" className="btn-trade">
            Access Pitchdeck
          </a>
        </div>
      </div>

      <div className="ourPartners" id="partners" ref={partnersRef}>
        <h3>OUR PARTNERS</h3>
        <div className="partners-logos col-8 mx-auto">
          <div className="row mt-10 align-items-center">
            <div className="col-3 text-center">
              <a href="https://www.yellow.org/" target="_blank" rel="noreferrer">
                <img src={yellowPartner} alt="yellow.org" />
              </a>
            </div>
            <div className="col-3 text-center">
              <a href="https://www.prasaga.com/" target="_blank" rel="noreferrer">
                <img src={PrasagaPartner} alt="Prasaga" />
              </a>
            </div>
            <div className="col-3 text-center">
              <a href="https://shardeum.org/" target="_blank" rel="noreferrer">
                <img src={ShardeumPartner} alt="Shardeum" />
              </a>
            </div>
            <div className="col-3 text-center">
              <a href="https://ten.xyz/" target="_blank" rel="noreferrer">
                <img src={OBSCPartner} alt="obscuro" />
              </a>
            </div>
          </div>

          <div className="row mt-10 align-items-center justify-content-center">
            <div className="col-3 text-center">
              <a href="https://damex.io/" target="_blank" rel="noreferrer">
                <img src={DAMEXPartner} alt="Damex" />
              </a>
            </div>
            <div className="col-3 text-center">
              <a href="https://www.numencyber.com/" target="_blank" rel="noreferrer">
                <img src={numenPartner} alt="numern" />
              </a>
            </div>

            <div className="col-3 text-center">
              <a href="https://www.verichains.io/" target="_blank" rel="noreferrer">
                <img width="80px" src={verichains} alt="Verichains" />
              </a>
            </div>
            <div className="col-3 text-center">
              <a href="https://www.tradingview.com/" target="_blank" rel="noreferrer">
                <img width="200px" src={TV} alt="Trading View" />
              </a>
            </div>
          </div>
        </div>
        <div
          className="text-center w-50"
          style={{ margin: "50px auto", fontFamily: "Lato", letterSpacing: "0.4px", fontSize: "14px" }}
        >
          <p className="partner-text">
            Charts and financial information provided by TradingView, a popular trading and charting platform. Check out
            the{" "}
            <a
              style={{ textDecoration: "none" }}
              href="https://www.tradingview.com/symbols/XAUUSD/"
              target="_blank"
              rel="noreferrer"
            >
              gold chart
            </a>{" "}
            or explore{" "}
            <a
              style={{ textDecoration: "none" }}
              href="https://www.tradingview.com/symbols/BTC.D/"
              target="_blank"
              rel="noreferrer"
            >
              Bitcoin's dominance
            </a>{" "}
            directly from their website.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};
