import { t } from "@lingui/macro";
import { useWeb3React } from "@web3-react/core";
import { useState } from "react";

import { SHARDEUM_TESTNET } from "config/chains";
import { useChainId } from "lib/chains";

export default function ShardeumDeposit(props) {
  const { active } = useWeb3React();
  const { chainId } = useChainId();

  const [depositAmount, setDepositAmount] = useState(1);

  const { depositMethod, maxDepositAmount } = props;

  const onAmountChange = (e) => {
    if (!e.target.value) {
      setDepositAmount(e.target.value);
      return;
    }
    const _depositAmount = e.target.value ? e.target.value : 0.0;
    setDepositAmount(_depositAmount);
  };

  const isPrimaryEnabled = () => {
    if (!active) {
      return false;
    }
    if (chainId !== SHARDEUM_TESTNET) {
      return false;
    }

    if (depositAmount <= 0.0) {
      return false;
    }

    return true;
  };

  const onClickPrimary = () => {
    depositMethod(depositAmount);
  };

  const getPrimaryText = () => {
    if (!active) {
      return t`Connect Wallet`;
    }
    if (chainId !== SHARDEUM_TESTNET) {
      return t`Wrong Network. Switch to Shardeum Testnet`;
    }
    if (depositAmount <= 0.0) {
      return t`Invalid Amount`;
    }
    return t`Deposit`;
  };

  return (
    <div className="shardeum-swap">
      <div className="shardeum-content">
        <div className="shardeum-label">
          <div className="shardeum-text">Deposit Amount</div>
        </div>
        <div className="shardeum-input-container">
          <input
            type="number"
            placeholder="0.0"
            maxLength="3"
            className="shardeum-input"
            value={depositAmount}
            onChange={onAmountChange}
          />
        </div>
      </div>
      <div className="button-container">
        <button className="shm-button-deposit" onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
          {getPrimaryText()}
        </button>
      </div>
    </div>
  );
}
