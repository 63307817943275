import { ethers } from "ethers";
import { SHARDEUM_GLOBAL_WALLET } from "config/context";
const provider = new ethers.providers.Web3Provider(window.ethereum);
const signer = provider.getSigner();

export const verifiedWithdraw = async ({ url, types, value }) => {
  const sign_url = `${url}/withdrawal`;
  //const sign_url = "http://localhost:3300/verify-signature"

  // Create EIP-712 data structure
  const domain = {
    name: "WalletVerifier",
    version: "1",
    chainId: value.chainId,
    verifyingContract: "0x0000000000000000000000000000000000000000", // Placeholder contract address
  };

  // Sign the EIP-712 message
  const signature = await signer._signTypedData(domain, types, value);
  // Send signature to server for verification
  try {
    const response = await fetch(sign_url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        signature: signature,
        address: value.wallet,
        timestamp: value.timestamp,
        chainId: value.chainId,
        action: value.action,
        amount: value.amount,
      }),
    });

    const result = await response.json();
    return result;
  } catch (e) {
    throw new Error(`Error varifying ${value}`);
  }
};

export const transfer = async ({ amount, targetWallet = SHARDEUM_GLOBAL_WALLET }) => {
  try {
    const tx = await signer.sendTransaction({
      to: targetWallet,
      value: ethers.utils.parseEther(amount.toString()),
    });
    return tx;
  } catch (error) {
    console.error("Error sending transaction:", error);
    throw error; // Re-throw the error if needed
  }
};
