

const ShardeumStatsRow = ({ data, walletVerified }) => {

  return (
<>
    <section className="shardeum-info" style={{ width: "80%", margin: "0 auto", padding: "0px 10px" }}>

      <div className="user-info-content">
        <p className="first-element">WALLET</p>
        <p className="second-element red">{data?.wallet?`${data.wallet.substring(0,3)}...${data.wallet.substring(data.wallet.length-3)}`:""}{walletVerified?"✅":"❌"}</p>
              </div>

      <div className="user-info-content">
        <p className="first-element">STAKING BALANCE</p>
        <p className="second-element" style={{ color: "#cafc4f" }}>{data?.stakingBalance?`${Intl.NumberFormat("es-CL").format((data?.stakingBalance).toFixed(0))} $SHM`:""}</p>
      </div>

      <div className="user-info-content">
        <p className="first-element">PENDING REWARDS</p>
        <p className="second-element">{data?.pendingRewards?`${Intl.NumberFormat("es-CL").format((data?.pendingRewards).toFixed(5))} $SHM`:""}</p>
      </div>

      <div className="user-info-content">
        <p className="first-element">PENDING WITHDR</p>
        <p className="second-element">{data?.pendingWithdrawals?data?.pendingWithdrawals:0}</p>
      </div>
      <div className="user-info-content">
        <p className="first-element">TOTAL DEPOSITS</p>
        <p className="second-element">{data?.totalDeposits?data?.totalDeposits:0}</p>
      </div>
      <div className="user-info-content">
        <p className="first-element">TOTAL REWARDS</p>
        <p className="second-element">{data?.totalRewards?data?.totalRewards:0}</p>
      </div>
      <div className="user-info-content">
        <p className="first-element">TOTAL WITHDR</p>
        <p className="second-element">{data?.totalWithdrawals?data?.totalWithdrawals:0}</p>
      </div>

    </section>

    {/*<div className="block-stat">

      <div className="stat">
        <span className="text-stat">Staked Tokens</span>
        <span className="number-stat">{data?`${Intl.NumberFormat("es-CL").format(
          (data?.stakedTokens).toFixed(0))} $`:""}</span>
      </div>
      <div className="stat">
        <span className="text-stat">Staking Rewards</span>
        <span className="number-stat">{data?`${Intl.NumberFormat("es-CL").format((data?.stakingRewards).toFixed(5))}`:""}</span>
      </div>
      <div className="stat">
        <span className="text-stat">Wallet</span>
        <span className="user-stat">{data?.wallet?`${data.wallet.substring(0,4)}...${data.wallet.substring(data.wallet.length-4)}`:""}</span>
      </div>
    </div>*/}
  </>
  );
};

export default ShardeumStatsRow;
