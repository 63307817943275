
import React, { useState } from "react";

import Hyperliquid from "../../img/HL symbol_mint green.svg";

const HyperliquidRow = ({ HyperliquidData }) => {
  const [parameter, setParameter] = useState("all");
  const handleClick = (time) => {
    setParameter(time);
  };

  return (
    <div className="block-stat">
      <div className="stat">
        <span className="red-text">
          <img width="28px" src={Hyperliquid} style={{ marginRight: "8px" }} />
          Hyperliquid
        </span>
        <div className="range">
          <button
            onClick={() => {
              handleClick("all");
            }}
            className={`button-range ${parameter === "all" ? "active" : ""}`}
          >
            ALL
          </button>
          <button
            onClick={() => {
              handleClick("30");
            }}
            className={`button-range ${parameter === "30" ? "active" : ""}`}
          >
            M
          </button>
          <button
            onClick={() => {
              handleClick("7");
            }}
            className={`button-range ${parameter === "7" ? "active" : ""}`}
          >
            W
          </button>
          <button
            onClick={() => {
              handleClick("1");
            }}
            className={`button-range ${parameter === "1" ? "active" : ""}`}
          >
            D
          </button>
        </div>
      </div>
      <div className="stat">
        <span className="text-stat">VOLUME</span>
        <span className="number-stat">{`${Intl.NumberFormat("es-CL").format(
          (HyperliquidData?.volume[parameter] / 1e10).toFixed(0)
        )} $`}</span>
      </div>
      <div className="stat">
        <span className="text-stat">TRADES</span>
        <span className="number-stat">{`${Intl.NumberFormat("es-CL").format(HyperliquidData?.trades[parameter])}`}</span>
      </div>
      <div className="stat">
        <span className="text-stat">USERS</span>
        <span className="user-stat">{`${Intl.NumberFormat("es-CL").format(HyperliquidData?.uniqueUsers[parameter])}`}</span>
      </div>
    </div>
  );
};

export default HyperliquidRow;
