import { useEffect, useState, useContext } from "react";
import "./Stats.css";
import OpBnbRow from "../../components/Stats/OpBnbRow.jsx";
import ShmRow from "components/Stats/ShmRow";
import ZkSynkRow from "components/Stats/ZkSynkRow";
import { useStats } from "hooks/useStats";
import ZetaChainRow from "components/Stats/ZetaChainRow";

import {
  ZKSYNC_TESTNET,
  OPBNB_TESTNET,
  SHARDEUM_TESTNET,
  ZETACHAIN_TESTNET,
  IOTA_TESTNET,
  TEN_TESTNET,
  BERACHAIN_TESTNET,
  FIRE_CHAIN_TESTNET,
  HYPERLIQUID_TESTNET
} from "config/chains";
import ShimmerRow from "components/Stats/ShimmerRow";
import BerachainRow from "components/Stats/BerachainRow";
import TenRow from "components/Stats/TenRow";
import FireRow from "components/Stats/FireRow";
import HyperliquidRow from "components/Stats/HyperliquidRow";

export const Stats = () => {
  const [datosCargados, setDatosCargados] = useState(false);
  const [parameter, setParameter] = useState("all");

  const [zkSyncData, setZkSynk] = useState();
  const [opBnbData, setOpBnb] = useState();
  const [shmData, setShm] = useState();
  const [zetaChainData, setZetaChain] = useState();
  const [shimmerData, setShimmerData] = useState();
  const [tenData, setTenData] = useState();
  const [berachain, setBerachain] = useState();
  const [fireData, setFireData] = useState();
  const [hlData, setHlData] = useState();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [show, setShow] = useState(
    JSON.parse(localStorage.getItem("show")) || {
      showTotal: true,
      showZkSynk: false,
      showOpBnb: false,
      showShm: false,
      showZetaChain: false,
      showShimmer: false,
      show5ire: false,
      showHyperliquid: false,
    }
  );

  const { stats: data } = useStats({ periodic: true, refreshInterval: 30000 });

  useEffect(() => {
    if (data) {
      const firstData = data[ZKSYNC_TESTNET];
      const secondData = data[OPBNB_TESTNET];
      const lastData = data[SHARDEUM_TESTNET];
      const newData = data[ZETACHAIN_TESTNET];
      const shimmerSVM = data[IOTA_TESTNET];
      const berachainData = data[BERACHAIN_TESTNET];
      const fireData = data[FIRE_CHAIN_TESTNET];
      const ten = data[TEN_TESTNET];
      const hlData = data[HYPERLIQUID_TESTNET];

      console.log("@FrankyPrograma", ten);

      setZkSynk(firstData);
      setOpBnb(secondData);
      setShm(lastData);
      setZetaChain(newData);
      setShimmerData(shimmerSVM);
      setBerachain(berachainData);
      setTenData(ten);
      setFireData(fireData);
      setHlData(hlData);

      setDatosCargados(true);
    }
  }, [data]);

  useEffect(() => {
    localStorage.setItem("show", JSON.stringify(show));
  }, [show]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (windowWidth > 950) {
      setShow({
        showTotal: true,
        showZkSynk: true,
        showOpBnb: true,
        showShm: true,
        showZetaChain: true,
        showShimmer: true,
        showTen: true,
        showBerachain: true,
        show5ire: true,
        showHyperliquid: true,
      });
    }
  }, [windowWidth]);

  const handleClick = (time) => {
    setParameter(time);
  };

  if (!datosCargados) {
    return <div></div>;
  }

  return (
    <div className="general-container">
      <p className="stats-title">STATISTICS</p>
      <div className="blocks-stat">
        <div className="accordion-stats">
          <div className="accordion-title">
            <h2>Grand Total Stats</h2>

            <button
              onClick={() => {
                setShow({
                  ...show,
                  showTotal: !show.showTotal,
                });
              }}
            >
              {show.showTotal ? <i class="fa-solid fa-chevron-up"></i> : <i class="fa-solid fa-chevron-down"></i>}
            </button>
          </div>
          <div className="accordion-item">
            {show.showTotal && (
              <div className="block-stat">
                <div className="stat">
                  <span className="red-text">Grand Total</span>
                  <div className="range">
                    <button
                      onClick={() => {
                        handleClick("all");
                      }}
                      className={`button-range ${parameter === "all" ? "active" : ""}`}
                    >
                      ALL
                    </button>
                    <button
                      onClick={() => {
                        handleClick("30");
                      }}
                      className={`button-range ${parameter === "30" ? "active" : ""}`}
                    >
                      M
                    </button>
                    <button
                      onClick={() => {
                        handleClick("7");
                      }}
                      className={`button-range ${parameter === "7" ? "active" : ""}`}
                    >
                      W
                    </button>
                    <button
                      onClick={() => {
                        handleClick("1");
                      }}
                      className={`button-range ${parameter === "1" ? "active" : ""}`}
                    >
                      D
                    </button>
                  </div>
                </div>
                <div className="stat">
                  <span className="text-stat">VOLUME</span>
                  <span className="number-stat">
                    {Intl.NumberFormat("es-CL").format(
                      (
                        (zkSyncData?.volume[parameter] +
                          shmData?.volume[parameter] +
                          opBnbData?.volume[parameter] +
                          berachain?.volume[parameter] +
                          shimmerData?.volume[parameter] +
                          zetaChainData?.volume[parameter] +
                          tenData?.volume[parameter] +
                          hlData?.volume[parameter] +
                          fireData?.volume[parameter]) /
                        1e10
                      ).toFixed(0)
                    )}{" "}
                    $
                  </span>
                </div>
                <div className="stat">
                  <span className="text-stat">TRADES</span>
                  <span className="number-stat">
                    {Intl.NumberFormat("es-CL").format(
                      zkSyncData?.trades[parameter] +
                        shmData?.trades[parameter] +
                        opBnbData?.trades[parameter] +
                        zetaChainData?.trades[parameter] +
                        berachain?.trades[parameter] +
                        shimmerData?.trades[parameter] +
                        tenData?.trades[parameter] +
                        hlData?.trades[parameter] +
                        fireData?.trades[parameter]
                    )}
                  </span>
                </div>
                <div className="stat">
                  <span className="text-stat">USERS</span>
                  <span className="user-stat">
                    {Intl.NumberFormat("es-CL").format(
                      zkSyncData?.uniqueUsers[parameter] +
                        shmData?.uniqueUsers[parameter] +
                        opBnbData?.uniqueUsers[parameter] +
                        zetaChainData?.uniqueUsers[parameter] +
                        berachain?.uniqueUsers[parameter] +
                        shimmerData?.uniqueUsers[parameter] +
                        tenData?.uniqueUsers[parameter] +
                        hlData?.uniqueUsers[parameter] +
                        fireData?.uniqueUsers[parameter]
                    )}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>


        <div className="accordion-stats">
          <div className="accordion-title">
            <h2>Ten Stats</h2>

            <button
              onClick={() => {
                setShow({
                  ...show,
                  showTen: !show.showTen,
                });

                console.log(show);
              }}
            >
              {show.showZkSynk ? <i class="fa-solid fa-chevron-up"></i> : <i class="fa-solid fa-chevron-down"></i>}
            </button>
          </div>
          <div className="accordion-item">{show.showTen && <TenRow tenData={tenData} />}</div>
        </div>

        <div className="accordion-stats">
          <div className="accordion-title">
            <h2>IOTA</h2>

            <button
              onClick={() => {
                setShow({
                  ...show,
                  showShimmer: !show.showShimmer,
                });
              }}
            >
              {show.showShimmer ? <i class="fa-solid fa-chevron-up"></i> : <i class="fa-solid fa-chevron-down"></i>}
            </button>
          </div>
          <div className="accordion-item">{show.showShimmer && <ShimmerRow shimmerData={shimmerData} />}</div>
        </div>

        <div className="accordion-stats">
          <div className="accordion-title">
            <h2>HL</h2>

            <button
              onClick={() => {
                setShow({
                  ...show,
                  showHyperliquid: !show.showHyperliquid,
                });
              }}
            >
              {show.showHyperliquid ? <i class="fa-solid fa-chevron-up"></i> : <i class="fa-solid fa-chevron-down"></i>}
            </button>
          </div>
          <div className="accordion-item">{show.showHyperliquid && <HyperliquidRow HyperliquidData={hlData} />}</div>
        </div>



        {/* <div className="accordion-stats">
          <div className="accordion-title">
            <h2>Shardeum Stats</h2>

            <button
              onClick={() => {
                setShow({
                  ...show,
                  showShm: !show.showShm,
                });
              }}
            >
              {show.showShm ? <i class="fa-solid fa-chevron-up"></i> : <i class="fa-solid fa-chevron-down"></i>}
            </button>
          </div>
          <div className="accordion-item">{show.showShm && <ShmRow shmData={shmData} />}</div>
        </div> */}

        <h2 className="title-stat">Previous Testnets</h2>
        <div className="accordion-stats">
          <div className="accordion-title">
            <h2>5ire Stats</h2>

            <button
              onClick={() => {
                setShow({
                  ...show,
                  show5ire: !show.show5ire,
                });

                console.log(show);
              }}
            >
              {show.show5ire ? <i class="fa-solid fa-chevron-up"></i> : <i class="fa-solid fa-chevron-down"></i>}
            </button>
          </div>

          <div className="accordion-item">{show.show5ire && <FireRow FireData={fireData} />}</div>
        </div>

        <div className="accordion-stats">
          <div className="accordion-title">
            <h2>Berachain</h2>

            <button
              onClick={() => {
                setShow({
                  ...show,
                  showBerachain: !show.showBerachain,
                });
              }}
            >
              {show.showBerachain ? <i class="fa-solid fa-chevron-up"></i> : <i class="fa-solid fa-chevron-down"></i>}
            </button>
          </div>
          <div className="accordion-item">{show.showBerachain && <BerachainRow berachainData={berachain} />}</div>
        </div>
        <div className="accordion-stats">
          <div className="accordion-title">
            <h2>zkSync Stats</h2>

            <button
              onClick={() => {
                setShow({
                  ...show,
                  showZkSynk: !show.showZkSynk,
                });

                console.log(show);
              }}
            >
              {show.showZkSynk ? <i class="fa-solid fa-chevron-up"></i> : <i class="fa-solid fa-chevron-down"></i>}
            </button>
          </div>
          <div className="accordion-item">{show.showZkSynk && <ZkSynkRow zkSyncData={zkSyncData} />}</div>
        </div>

        <div className="accordion-stats">
          <div className="accordion-title">
            <h2>ZetaChain Stats</h2>

            <buttonx
              onClick={() => {
                setShow({
                  ...show,
                  showZetaChain: !show.showZetaChain,
                });

                console.log(show);
              }}
            >
              {show.showZetaChain ? <i class="fa-solid fa-chevron-up"></i> : <i class="fa-solid fa-chevron-down"></i>}
            </buttonx>
          </div>
          <div className="accordion-item">{show.showZetaChain && <ZetaChainRow zetaChainData={zetaChainData} />}</div>
        </div>

        <div className="accordion-stats">
          <div className="accordion-title">
            <h2>opBNB Stats</h2>

            <button
              onClick={() => {
                setShow({
                  ...show,
                  showOpBnb: !show.showOpBnb,
                });
              }}
            >
              {show.showOpBnb ? <i class="fa-solid fa-chevron-up"></i> : <i class="fa-solid fa-chevron-down"></i>}
            </button>
          </div>
          <div className="accordion-item">{show.showOpBnb && <OpBnbRow opBnbData={opBnbData} />}</div>
        </div>
      </div>
      <br></br>
      <br></br>
    </div>
  );
};
