import useSWR from "swr";
import { useContext } from "react";
import AppContext from "AppContext";

export function useShardeumStats({ account, periodic = false, refreshInterval = 10000, mock = false }) {
  const { MODE, filteredEndpointsInfo } = useContext(AppContext);

  //const STATS_URL = getUrl(PROD, "STATS");
  const STAKING_STATS_URL = filteredEndpointsInfo.shardeum_staking;

  const fetchStatsData = async () => {
    console.log("STATS_URL", STAKING_STATS_URL);
    console.log("STATS_URRL1", !STAKING_STATS_URL);
    if (!STAKING_STATS_URL) return;
    console.log("STATS_URL1", STAKING_STATS_URL);
    if (mock) {
      const timestamp = Math.floor(Date.now() / 1000);
      return {
        success: true,
        data: {
          wallet: "0x3d2e7534e563775061c3ef61973b96855e7f1e64",
          totalDeposits: 1000,
          totalWithdrawals: 500,
          stakingBalance: 500,
          pendingRewards: 25 + (timestamp % 1742400000) / 100000,
          totalRewards: 100,
          pendingWithdrawals: 0,
          timestamp: timestamp,
        },
      };
    }
    console.log("account", account);
    const url = `${STAKING_STATS_URL}/user-info`;
    const body = { address: account };
    console.log("body", body);
    return fetch("https://c.ss.dexynth.com/user-info", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }).then((resp) => resp.json());
  };

  const fetchNodeStatsData = async () => {
    if (!STAKING_STATS_URL) return;
    const url = `${STAKING_STATS_URL}/nodes-info`;
    if (mock) {
      const timestamp = Math.floor(Date.now() / 1000);
      return {
        success: true,
        data: {
          Epoch: 701,
          EpochStart: 1742541710.387,
          EpochEnd: 1742541770.387,
          TotalTokensStaked: 101,
        },
      };
    }

    return fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((resp) => resp.json());
  };

  const { data: user_info, error: user_error } = useSWR(periodic && ["useShardeumStats.User"], fetchStatsData, {
    refreshInterval: refreshInterval,
    revalidateOnFocus: true,
  });

  const { data: nodes_info, error: node_error } = useSWR(periodic && ["useShardeumStats.Nodes"], fetchNodeStatsData, {
    refreshInterval: refreshInterval,
    revalidateOnFocus: true,
  });

  return { user_info, nodes_info };
}
