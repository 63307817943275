import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { useEffect, useState } from "react";


const ShardeumNodeStatsRow = ({ data, walletVerified }) => {
  /*Epoch: 701,
  EpochStart: 1742541710.387,
  EpochEnd: 1742541770.387,
  TotalTokensStaked: 101,*/
  const [timeRemaining, setTimeRemaining] = useState("");

  const epochToTime = (epoch) => {
    if (!epoch) return "UNKNOWN";    
    const date = new Date(epoch * 1000);    
    return format(date, 'dd/MM/yyyy HH:mm:ss', {locale: es});
  };

  
  const calculateTimeRemaining = () => {
    if (!data?.EpochEnd) return "UNKNOWN";
    const now = new Date().getTime(); // Tiempo actual en milisegundos
    const epochEnd = data.EpochEnd * 1000; // Convertir EpochEnd a milisegundos
    const diff = epochEnd - now; // Diferencia entre el tiempo actual y el EpochEnd

    if (diff <= 0) return "00:00:00"; // Si ya pasó, mostrar 0

    const hours = Math.floor(diff / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((diff % (1000 * 60)) / 1000);

    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };

  
  useEffect(() => {
    const interval = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);

    return () => clearInterval(interval); 
  }, [data?.EpochEnd]);
    


  return (
<>
    <section className="shardeum-info" style={{ width: "80%", margin: "0 auto", padding: "0px 10px" }}>

      <div className="node-info-content">
        <p className="first-element">EPOCH</p>
        <p className="second-element red">{data?.Epoch?data?.Epoch:0}</p>
      </div>

      <div className="node-info-content">
        <p className="first-element">TOTAL TOKENS STAKED</p>
        <p className="second-element">{data?.TotalTokensStaked?data?.TotalTokensStaked:0} $SHM</p>
      </div>
      <div className="node-info-content">
        <p className="first-element">EPOCH START</p>
        <p className="second-element">{data?.EpochStart?epochToTime(data?.EpochStart):"UNKNOWN"}</p>
      </div>
      <div className="node-info-content">
        <p className="first-element">EPOCH END</p>
        <p className="second-element">{data?.EpochEnd?epochToTime(data?.EpochEnd):"UNKNOWN"}</p>
      </div>        
      <div className="node-info-content">
          <p className="first-element">TIME REMAINING</p>
          <p className="second-element">{timeRemaining}</p>
        </div>

    </section>

    {/*<div className="block-stat">

      <div className="stat">
        <span className="text-stat">Staked Tokens</span>
        <span className="number-stat">{data?`${Intl.NumberFormat("es-CL").format(
          (data?.stakedTokens).toFixed(0))} $`:""}</span>
      </div>
      <div className="stat">
        <span className="text-stat">Staking Rewards</span>
        <span className="number-stat">{data?`${Intl.NumberFormat("es-CL").format((data?.stakingRewards).toFixed(5))}`:""}</span>
      </div>
      <div className="stat">
        <span className="text-stat">Wallet</span>
        <span className="user-stat">{data?.wallet?`${data.wallet.substring(0,4)}...${data.wallet.substring(data.wallet.length-4)}`:""}</span>
      </div>
    </div>*/}
  </>
  );
};

export default ShardeumNodeStatsRow;
