import { useEffect, useState, useContext } from "react";
import AppContext from "AppContext";

import "./Shardeum.css";

import ShardeumStatsRow from "components/Shardeum/ShardeumStatsRow";
import ShardeumNodeStatsRow from "components/Shardeum/ShardeumNodeStatsRow";
import ShardeumWithdraw from "components/Shardeum/ShardeumWithdraw";
import ShardeumDeposit from "components/Shardeum/ShardeumDeposit";


import { useWeb3React } from "@web3-react/core";
import { useChainId } from "lib/chains";


import { verifiedWithdraw, transfer } from "../../services/shardeumStakingServices.js";
import { useShardeumStats } from "hooks/useShardeumStats";

export const Shardeum = () => {

  const { MODE, filteredEndpointsInfo } = useContext(AppContext);
  const { chainId } = useChainId();
  const { account } = useWeb3React();
  
  const [signing, setSigning] = useState(false);
  const [signResult, setSignResult] = useState({ verified: false , message: "", error: ""});
  const [walletVerified, setWalletVerified] = useState(false);
  
  const [userData, setUserData] = useState({})
  const [nodesData, setNodesData] = useState({})
  

  useEffect(() => {
    setWalletVerified(signResult?.verified);
  }, [signResult]);
  
  const types = {
    Verification: [
      { name: "wallet", type: "address" },
      { name: "timestamp", type: "uint256" },
      { name: 'chainId', type: 'uint256' },
      { name: 'action', type: 'string' },
      { name: 'amount', type: 'string' }
    ],
  };  

  const {  user_info, nodes_info } = useShardeumStats({ account: account, periodic: true, refreshInterval: 10000, mock: false });

  console.log(nodes_info)

  useEffect(() => {
    if (user_info?.success) {
      console.log(user_info);      
      setUserData(user_info.data)
    }
  }, [user_info]);

  useEffect(() => {
    if (nodes_info?.success) {
      console.log(nodes_info);      
      setNodesData(nodes_info.data)
    }
  }, [nodes_info]);
    

  const signMessage = async (withdrawalAmount) => {
      setSigning(true);

      const action = "withdraw";
      const timestamp = Math.floor(Date.now() / 1000);
      const value = {
        wallet: account,
        timestamp: timestamp,                
        chainId: chainId,
        action: action,
        amount: withdrawalAmount.toString(),
      };
      
      try {
        setSigning(true);
        const _signResult = await verifiedWithdraw({ 
          url: filteredEndpointsInfo.shardeum_staking,
          account: account, 
          chainId : chainId, 
          types: types, 
          value: value });
        setSignResult(_signResult);
      } catch (e) {
        console.error(e.message);
      } finally {
        setSigning(false);
      }    
  };

  const deposit = async (depositAmount) => {
    console.log("deposit", depositAmount)
    transfer({amount : depositAmount})
      .then(tx => {
        console.log(tx)
      })
      .catch(error => {
        console.log(error)
      })
      
  };
  



  return (
    <div className="general-container">
      <p className="stats-title">SHARDEUM STAKING</p>
      <div className="blocks-stat">          
          <h2 className="title-stat">Node Statistics</h2>
            <div className="accordion-stats">
              <div className="accordion-title">
                <h2>NODE STATS</h2>
              </div>
              <div className="accordion-item">{<ShardeumNodeStatsRow data={nodesData}  />}</div>
            </div>
        </div>
        
      <div className="blocks-stat">
          
          <h2 className="title-stat">User Statistics</h2>
            <div className="accordion-stats">
              <div className="accordion-title">
                <h2>USER STATS</h2>
              </div>
              <div className="accordion-item">{<ShardeumStatsRow data={userData}  walletVerified={walletVerified} />}</div>
          </div>            
      </div>
      <br></br>
      <div className="staking-block">                   
          <ShardeumDeposit data={userData} depositMethod={deposit} />
          <ShardeumWithdraw data={userData} withdrawMethod={signMessage}  />
      </div>
      <br></br>
      <br></br>
    </div>
  );
};
